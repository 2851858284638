<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/skipshare">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Edit Skip</h4>
          </div>
        </div>

        <div class="card-body">
          <div>
            <b-form>
              <b-form-group
                id="input-group-3"
                label="Customer:"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  placeholder="Select customer"
                  ><span slot="noResult">No List found!.</span>
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }}({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Space:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="space"
                  :options="spaces"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Size:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="size"
                  :options="sizes"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Expiry Date:"
                label-for="input-2"
              >
                <b-form-datepicker
                  v-model="expirydate"
                  :min="new Date().toISOString().substr(0, 10)"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Post Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  @keyup="isZipCodeValid"
                  type="text"
                  required
                  placeholder="Enter post Code"
                ></b-form-input>
              </b-form-group>
              <div
                v-show="!this.zipcodeValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Zip Code
              </div>

              <b-form-group
                id="input-group-2"
                label="Price for Skip: £"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="price"
                  @keypress="onlyForCurrency"
                  type="text"
                  required
                  placeholder="Enter price"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  placeholder="Enter description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Instruction:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="instruction"
                  placeholder="Enter instruction here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Cover Image:"
                label-for="input-2"
              >
                <input type="file" @change="coverImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="coverimageUrl"
                  :src="coverimageUrl"
                />
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
      <br /><br />

      <div class="card">
        <div class="col-12 mt-2">
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>
          <br />
          <b-form-group
            id="input-group-2"
            label="Upload Images:"
            label-for="input-2"
          >
            <input
              type="file"
              ref="file"
              multiple="multiple"
              @change="submitFiles"
            />
          </b-form-group>

          <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
            <b-row>
              <b-col-4
                v-for="(image, key) in imagesArray"
                :key="key"
                class="p-3"
              >
                <button
                  style="color:white"
                  class="close ml-1"
                  @click.prevent="removeImage(image)"
                >
                  &times;
                </button>
                <b-img
                  style="height: 150px;max-width: 165px;"
                  thumbnail
                  fluid
                  :src="getLogo(image)"
                  alt="Image 1"
                ></b-img>
              </b-col-4>
            </b-row>
          </b-container>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Images</h4>
          </div>
        </div>

        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(image_name)="loans">
                <b-img
                  style="height: 70px;width: 72px;border-radius: 50%;"
                  thumbnail
                  fluid
                  :src="getLogo(loans.item.image_name)"
                  alt="Image 1"
                ></b-img>
              </template>

              <template v-slot:cell(actions)="loans">
                <button
                  v-bind:class="'btn btn-danger btn-sm'"
                  @click="deleteUser(loans)"
                >
                  Delete</button
                >&nbsp;
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
          <b-button ref="save" @click="onSubmit()" variant="primary"
            >Save</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import axios from "axios";
import VerficationModal from "./VerficationModal";
import Multiselect from "vue-multiselect";

export default {
  components: {
    VerficationModal,
    Multiselect,
  },
  data() {
    return {
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      expirydate: "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "id",
      imagesShow: false,
      filterOn: [],
      showDeleteModal: false,
      sortDesc: true,
      fields: [
        { key: "image_name", label: "Image", sortable: true },
        { key: "actions", sortable: true },
      ],
      showModal: false,
      imagesArray: [],
      loans: [],
      description: "",
      instruction: "",
      coverimageUrl: null,
      coverImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      space: "",
      price: "",
      zipcode: "",
      zipcodeValidation: true,
      sizes: ["Small", "Medium", "Large"],
      spaces: [
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60",
        "65",
        "70",
        "75",
        "80",
        "85",
        "90",
        "95",
        "100",
      ],
      show: true,
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },

  mounted() {
    this.getAllCustomer();
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.skipSharePercentage = currentUser.skip_share_percentage;
    this.editRecord = JSON.parse(localStorage.getItem("editskip"));
    if (this.editRecord.coverImage) {
      this.coverimageUrl = this.getLogo(this.editRecord.coverImage);
      this.coverImage = this.editRecord.coverImage;
    }
    this.loans = this.editRecord.images;
    this.id = this.editRecord.id;
    this.space = this.editRecord.space;
    this.size = this.editRecord.size;
    this.expirydate = this.editRecord.expiry_date;
    this.zipcode = this.editRecord.zipcode;
    this.price = this.editRecord.price.toFixed(2);
    this.description = this.returnEmptyValue(this.editRecord.description);
    this.instruction = this.returnEmptyValue(this.editRecord.instruction);
    this.selected = this.editRecord.userDetail;
  },
  methods: {
    formatNumber(e) {
      return String(e).substring(0, 9);
    },
    returnEmptyValue(value) {
      if (value == "null") {
        return (value = "");
      } else {
        return value;
      }
    },

    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.price.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.price != null &&
        this.price.indexOf(".") > -1 &&
        this.price.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    isZipCodeValid: function isZipCodeValid() {
      var regex1 = /^([A-Z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|[Gg][Ii][Rr] ?0[A]{2})$/;
      var responcend = regex1.test(this.zipcode);
      if (responcend) {
        this.zipcodeValidation = true;
      } else {
        this.zipcodeValidation = false;
      }
      return responcend;
    },

    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },
    onSubmit() {
      // let sum = 0;
      // let skipSharePercentageCalculation = parseFloat(
      //   ((this.price / 100) * this.skipSharePercentage).toFixed(2)
      // );
      // sum +=
      //   parseFloat(skipSharePercentageCalculation) + parseFloat(this.price);
      try {
        const formData = new FormData();

        if (this.selected == null) {
          this.alert = true;
          this.alertText = "Please select customer";
          return false;
        }
        if (this.expirydate == "") {
          this.alert = true;
          this.alertText = "Expiry Date is required";
          return false;
        }
        if (this.imagesArray.length < 2 && this.loans.length < 2) {
          this.alert = true;
          this.alertText = "At Least 2 images are mandatory";
          return false;
        }

        if (!this.zipcodeValidation) {
          return false;
        }
        this.addLoaderClasses("save");
        formData.append("user_id", this.selected.id);
        formData.append("id", this.id);
        formData.append("size", this.size);
        formData.append("space", this.space);
        formData.append("zipcode", this.zipcode);
        formData.append("price", this.price);
        formData.append("description", this.description);
        formData.append("instruction", this.instruction);
        formData.append("expiry_date", this.expirydate);
        formData.append("images", JSON.stringify(this.imagesArray));
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-share-store", formData, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              localStorage.setItem(
                "alertmessage",
                JSON.stringify("Record has been updated successfully")
              );
              this.$router.push({ name: "skipshare" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        alert(err);
      }
    },
    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },
    getAllCustomer() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.imagesArray = response.data.data;
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete-skip-image";
    },
    closeModal(value) {
      this.isShowModel = value;
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        // this.loans.splice(index, 1);
        this.editRecord.images.splice(index, 1);
        this.loans = this.editRecord.images;
        localStorage.removeItem("editskip");
        localStorage.setItem("editskip", JSON.stringify(this.editRecord));
      }

      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}
</style>
